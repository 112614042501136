<template>
  	<el-dialog title="Istoric Versiuni" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
        <el-table :data="Istoric" v-loading="loading">
            <el-table-column prop="Nume" label="Nume"></el-table-column>
            <el-table-column prop="DataCreare" label="Data Creare"></el-table-column>
            <el-table-column prop="DataValidare" label="Data Validare"/>
            <el-table-column prop="EsteActiv" label="Activ"/>       
        </el-table>

		<span slot="footer" class="dialog-footer">
			<el-button @click="showPopup = false" > Ok  </el-button>
		</span>


  	</el-dialog>
</template>

<script>
// Advanced Use - Hook into Quill's API for Custom Functionality

import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage'

export default {
    Nume: "Istoric_versiuni_dialog",
    extends: BasePage,    
    components: {
      
    },
    data () {
      	return {
			Istoric: [],
            showPopup: false,
            loading: false
      }
    },
    methods: {
        show_me: async function( istoric ) {
            this.Istoric = istoric
            this.showPopup    = true;
            
        },
        // async get_info(){
        //     var response 					= await this.post("template_formulare/get_info_for_dialog" );
		// 	this.Info.functii_angajati		= response.functii_angajati
		// 	this.Info.grupuri_utilizatori	= response.grupuri_utilizatori
		// 	this.Info.headers 				= response.headers
		// 	this.Info.footers 				= response.footers
        // },
    },
    mounted: function() {

    }
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}

.formular-draggable{
	margin-bottom: 20px;
	.list {
        background-color: #EBEEF5;
        font-size: 14px;
        .header.el-row {
            background-color: #FFF;
            color: #909399;
            font-weight: 500;            
        }
        .el-row {
            background-color: #FFF;
            transition: background-color .25s ease;
            border-bottom: 1px solid #EBEEF5;
            color: black;
            height: 40px;
            .el-col {
                border-bottom: 1px solid #EBEEF5;
                padding-top: 5px;
                padding-bottom: 5px;
                height: 100%;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 0;
                display: flex;
                
                align-items: center;
                line-height: normal;
            }
        }
    }

	.card-tabel-lista{
		margin-bottom:20px;
	}
	.card-principal{
		vertical-align: middle
	}
	.buton-adauga-tabel{
		position: absolute;
    	top: 50%;
	}
}

</style>