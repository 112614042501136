<template>
        <base-page>
        <titlu-pagina Titlu="Proceduri" @on_add_clicked="show_dialog()" :AdaugaVisible='true' />

        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix">
                <strong> Filtre </strong>
            </div>
            <div class="filtre">
                <el-form @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        <el-col :span='4'>
                            <el-form-item label='Nume' >
                                <el-input v-model='Filters.Nume' />
                            </el-form-item>
                        </el-col>         
                        <el-col :span='4'>
                            <el-form-item label='Stare' prop="Stare">
                                <el-select class='full-width' v-model="Filters.Stare" placeholder="Select">
                                    <el-option label="Draft"  value="draft"></el-option>
                                    <el-option label="In circuitul de aprobare" value="in_circuitul_de_aprobare"></el-option>
                                    <el-option label="Aprobat"  value="aprobat"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>                 
                        <el-col :md='24' >
                            <el-button @click="reset()" > Reset  </el-button>
                            <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        </el-col>
                    </el-row>
                </el-form>
                </div>
        </el-card>
           
        <el-table :data="Results" v-loading="loading">
            <el-table-column prop='Nume' label='Nume Procedura'></el-table-column>
            <el-table-column prop='DataCreare' label='Data creare'></el-table-column>
            <el-table-column prop='Descriere' label='Descriere'></el-table-column>

            <el-table-column prop='Stare' label='Stare'></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >
                    <el-tooltip v-if="scope.row.Fisier" content="Vizualizare pdf">
                        <el-button type="primary" icon="el-icon-view"  circle @click="view_pdf(scope.row.Fisier)" />
                    </el-tooltip>
                    <el-tooltip content="Editare procedura">
                        <el-button type="primary" icon="el-icon-edit"  circle @click="edit(scope.row)" />
                    </el-tooltip>
                    <el-tooltip content="Sterge" v-if="$has_right('stergere-procedura')">
                        <el-button type="danger" icon="el-icon-delete" circle @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        
    <el-pagination @size-change="refresh_info" @current-change= "refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />
    <Istoric-versiuni-dialog ref='dlg' />
    <Proceduri-dialog ref='dlg' @refresh="refresh_info()" />

</base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import UploadDocumente from '@/components/UploadDocumente'
import Proceduri_dialog from '@/pages/proceduri/Proceduri_dialog.vue';
import Istoric_versiuni_dialog from './Istoric_versiuni_dialog.vue'

export default {
    Nume: "proceduri",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        'Proceduri-dialog': Proceduri_dialog,
        UploadDocumente,
        'Istoric-versiuni-dialog': Istoric_versiuni_dialog
    },
    data () {
        return {
            Results: [],
            base_url: '',
            Info: { tipuri_formulare: [] },                            
            Filters: {Nume: '', Stare:''},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true
        }
    },
    methods: {
        async get_info(){
            var response = await this.post("formulare/get_info" );
            this.Info.tipuri_formulare = response.tipuri_formulare
            this.refresh_info();
        },
        async refresh_info(){
            var response    = await this.post("template_formulare/get_proceduri", { Filters: this.Filters, PaginationInfo: this.PaginationInfo});
            this.Results    = response.Proceduri;
            this.loading    = false            
        },
        reset(){
            this.Filters = {Nume: '', Stare:''};                
            this.refresh_info();
        },     
        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },   
        view_pdf(fisier){
            // window.open("http://ananp.brk-dev.ro/api/assets/proceduri/" + fisier, '_blank');
            let url = settings.BASE_URL.replace('index.php/', '') + "assets/proceduri/" + fisier;
            window.open(url, '_blank');
        },
        async delete_item( item ){
            var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning');
            if( confirm )
            {
                await this.post("template_formulare/delete_proceduri", { id: item.Id } );
			await this.post("template_formulare/remove_pdf", { fisier: item.Fisier} );
                this.refresh_info();
            }
        },
        async edit(item){
            this.$refs.dlg.show_me(item);

        }
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();        
    }
};
</script>

<style lang="less" scoped>

.top50{
  margin-top: 20px;
}

.filtre{
  .el-input-number
  {
    width: 100% !important;
  }
}

</style>
