<template>
  	<el-dialog title="Procedura" width="75%" :visible.sync="showPopup" class="my-dialog-class" :close-on-click-modal='false'>
		<el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='selectedObject' @submit.prevent="save" v-loading="loadingVisible" >
			<el-row :gutter="15" >      
				<el-col :md='8'> 
					<el-form-item label='Nume' prop="Nume" >
						<el-input  class='full-width' v-model='selectedObject.Nume' />
					</el-form-item>
				</el-col> 
				<el-col :md='8'> 
					<el-form-item label='Descriere' prop="Descriere" >
						<el-input  class='full-width' v-model='selectedObject.Descriere' />
					</el-form-item>
				</el-col> 
				<el-col :md="5">
					<el-form-item label='Stare' prop="Stare">
						<el-select class='full-width' v-model="selectedObject.Stare" placeholder="Select">
							<el-option label="Draft"  value="draft"></el-option>
							<el-option label="In circuitul de aprobare" value="in_circuitul_de_aprobare"></el-option>
							<el-option label="Aprobat"  value="aprobat"></el-option>
							<el-option label="Revizuit"  value="revizuit"></el-option>

						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item label="Fisier">
			 <el-col :span="8"> 
                <div class="container-fluid">
                    <el-upload
						v-if="!selectedObject.Fisier"
                       	:action="baseUrl + 'template_formulare/upload_document_procedura'"
						list-type="picture-card"
						:on-preview="handlePictureCardPreview"
						:on-remove="remove_pdf"
						:on-success="handleUploadSuccess">
                        <i class="el-icon-upload"></i>
                    </el-upload>
                </div>
                <div v-if="selectedObject.Fisier != '' && selectedObject.Fisier  != null">
                    <div class="cvControls">
                        <div><el-button type="success" @click="view_pdf()">Vizualizare fisier</el-button></div>
                        <div><el-button type="danger" @click="remove_pdf()">Stergere fiser</el-button></div>
                    </div>
                </div>            
            </el-col>
			</el-form-item>
		</el-form>

		<span slot="footer" class="dialog-footer">
			<el-button @click="resetForm('selectedObject')" > Renunta  </el-button>
			<el-button type="primary" @click="save('selectedObject')" > Salveaza </el-button>
		</span>

  	</el-dialog>
</template>

<script>
import BasePage from '@/pages/BasePage'
import settings from "@/backend/LocalSettings";

export default {
    Nume: "Proceduri_dialog",
    extends: BasePage,    
    components: {
    },
    data () {
      	return {
			showPopup: false,
			mode: 'add',
            baseUrl: '',
			dialogImageUrl: '',
            previewVisible:false,
			selectedObject: { Nume: '', Descriere: '', Stare: '', Fisier: '' },          

			rules: {
				Nume: [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
				Stare: [{ required: true, message: 'Campul este obligatoriu', trigger: 'blur'}],
			},
			multipleSelection: [],
			tabelListaHeight:{},
			existingVars:[]
      }
    },
    methods: {
        show_me: async function( item ) {
            this.showPopup = true;
			// console.log(this.selectedObject);
            if( item == null )
            {
                this.mode = "add";
				this.selectedObject = { Nume: '', Descriere: '', Stare: '', Fisier: '' }; 
            }
            else
            {
                this.mode = "edit";
				this.selectedObject = item;
            }
        },
        async save(formNume) {
			// console.log(this.selectedObject);
			
			this.$refs[formNume].validate( async(valid) => {
				if (valid)
				{
					await this.post("template_formulare/save_procedura", { mode: this.mode, object: this.selectedObject} );
					this.resetForm(formNume)
				} else {console.log('eroare validare formular!');}
			});                
		},
		resetForm(formNume) {
			this.$refs[formNume].resetFields();
			this.selectedObject = { Nume: '', Descriere: '', Stare: '', Fisier: '' };
			this.showPopup = false
			this.$emit("refresh");
		},
		view_pdf(){
            // window.open("http://ananp.brk-dev.ro/api/assets/proceduri/" + this.selectedObject.Fisier, '_blank');
			let url = settings.BASE_URL.replace('index.php/', '') + "assets/proceduri/" + this.selectedObject.Fisier;
            window.open(url, '_blank');
        },
		async remove_pdf() {
			await this.post("template_formulare/remove_pdf", { fisier: this.selectedObject.Fisier} );
			this.selectedObject.Fisier = "";
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleUploadSuccess(response){
			this.selectedObject.Fisier = response.FileName;
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.previewVisible = true;
		},
		setImgUrl() {
                var url = settings.BASE_URL;
                this.baseUrl = url;
            },
    },

    mounted: function() {
        this.setImgUrl();
    }
};
</script>

<style lang="less" scoped>
	.full-width {
	width: 100%;
	}

	.cvControls {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		margin-left: auto;
		margin-right: auto;
		margin: 10px;
		
	}

	.formular-draggable{
		margin-bottom: 20px;
		.list {
			background-color: #EBEEF5;
			font-size: 14px;
			.header.el-row {
				background-color: #FFF;
				color: #909399;
				font-weight: 500;            
			}
			.el-row {
				background-color: #FFF;
				transition: background-color .25s ease;
				border-bottom: 1px solid #EBEEF5;
				color: black;
				height: 40px;
				.el-col {
					border-bottom: 1px solid #EBEEF5;
					padding-top: 5px;
					padding-bottom: 5px;
					height: 100%;
					padding-left: 10px;
					padding-right: 10px;
					min-width: 0;
					display: flex;
					
					align-items: center;
					line-height: normal;
				}
			}
		}

		.card-tabel-lista{
			margin-bottom:20px;
		}
		.card-principal{
			vertical-align: middle
		}
		.buton-adauga-tabel{
			position: absolute;
			top: 50%;
		}
	}
</style>